@tailwind base;

@tailwind components;

@tailwind utilities;


.transition-theme * {
    transition: background-color 1s, color 1s, border-color 1s;
}
