/* Webfont CSS setup for Reactor7 by Caveras */

@font-face {
	font-family:'Reactor7';
		src:url('Reactor7.eot');
		src:url('Reactor7.eot?#iefix') format('embedded-opentype'),
				url('Reactor7.woff') format('woff'),
				url('Reactor7.ttf') format('truetype'),
				url('Reactor7.svg#Reactor7') format('svg');
	font-weight:normal;
	font-style:normal;
}

@font-face {
	font-family:'Reactor7';
		src: url('Reactor7Bold.svg#Reactor7') format('svg');
	font-weight: bold;
	font-style: normal;
}


@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family:'Reactor7';
		src: url('Reactor7.svg') format('svg');
		font-weight:normal;
		font-style:normal;
	}
}